'use client';
import React, { CSSProperties, ReactNode, forwardRef } from 'react';
import { useFormContext } from './Form';
import { useFormControlContext } from './FormControl';
import Loading from '../Misc/Loading';

export type ButtonProps = {
	className?: string;
	style?: CSSProperties;
	children?: ReactNode;
	type?: HTMLButtonElement['type'];
	disabled?: boolean;
	isLoading?: boolean;
	onClick?: () => void;
};

export default forwardRef<HTMLButtonElement, ButtonProps>(function Button(props, ref) {
	const { className, style, children, type, isLoading, onClick } = props;

	const formContext = useFormContext();
	const formControl = useFormControlContext();

	const disabled = props.disabled || formControl?.disabled || formContext?.disabled || isLoading;

	return (
		<button
			ref={ref}
			className={`Button ${className ?? ''} ${isLoading ? 'loading' : ''}`}
			style={style}
			type={type}
			disabled={disabled}
			onClick={onClick}
		>
			{children} {isLoading && <Loading size="xsmall" />}
		</button>
	);
});
