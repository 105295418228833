'use client';

import React, { ReactNode, createContext, useContext, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import Label from './Label';
import { useFormContext } from './Form';

export type FormControlContextType = {
	fieldId: string;
	name: string;
	label?: ReactNode;
	required?: boolean;
	disabled?: boolean;
};

const FormControlContext = createContext<FormControlContextType | null>(null);

export type FormControlProps = {
	className?: string;
	children?: ReactNode;
	label?: ReactNode;
	required?: boolean;
	name: string;
	disabled?: boolean;
	error?: string;
};

export default function FormControl(props: FormControlProps) {
	const { className, children, label, required, name, disabled } = props;

	const formContext = useFormContext();

	const fieldId = useMemo(() => uuid(), []);

	const formControlContext: FormControlContextType = {
		fieldId,
		name,
		label,
		required,
		disabled,
	};

	const error = formContext?.form.errors[name] ?? props.error;
	const hasSubmitted = formContext?.hasSubmitted;
	const isValid = formContext?.form.isValid(name);
	const isDirty = formContext?.form.isDirty(name);
	const isTouched = formContext?.form.isTouched(name);
	const isPristine = !isTouched;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const classNames = ['FormControl', formContext ? typeof (formContext.form.values as any)[name] : ''];
	if (className) {
		classNames.push(className);
	}
	if (isValid) {
		classNames.push('valid');
	} else {
		classNames.push('invalid');
	}
	if (isDirty) {
		classNames.push('dirty');
	}
	if (isTouched) {
		classNames.push('touched');
	}
	if (isPristine) {
		classNames.push('pristine');
	}
	if (hasSubmitted) {
		classNames.push('submitted');
	}

	return (
		<FormControlContext.Provider value={formControlContext}>
			<div className={classNames.join(' ')} data-control-name={name}>
				{label && <Label>{label}</Label>}
				{children}
				{error && <div className="error">{error}</div>}
			</div>
		</FormControlContext.Provider>
	);
}

export function useFormControlContext() {
	return useContext(FormControlContext);
}
